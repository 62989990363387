import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from 'moment'

import homeMF from '../views/homeMF.vue'
import homeTL from '../views/homeTL.vue'
import selectGame from '../views/selectGame.vue'

moment.locale('pt');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: selectGame
  },
  {
    path: '/fivem',
    name: 'homeMF',
    component: homeMF
  },
  {
    path: '/minecraft',
    name: 'homeMF',
    component: homeMF
  },
  {
    path: '/throne-and-liberty',
    name: 'homeTL',
    component: homeTL
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };
  }
})

router.beforeResolve((to, from, next) => {
  console.clear()

  if (to.path) {
    let path = to.path.replace('https://', '').replace('http://', '').split('/')
    if (path[1] == 'throne-and-liberty') {
      Vue.prototype.$typeGame = 1
    } else {
      Vue.prototype.$typeGame = 0
    }
  }

  next()
})

export default router
