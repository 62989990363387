import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import ElementUI from 'element-ui';

import axios from 'axios'
import moment from 'moment'

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$typeGame = 0

Vue.prototype.$urlAPI = 'https://fivem-webservice.hubcodes.com.br/'
Vue.use(ElementUI)
Vue.use(Flicking)

moment.locale('pt');
moment.updateLocale('pt', {
  months : [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
