<template>
  <section class="hosting-plan-area p-relative pt-50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title white-title text-center mb-75">
            <h2>Adquira já seu painel</h2>
            <p>Planos para você usar sem limites!</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Gratuito</span>
              <h3>R$0 <small>/ mês</small></h3>
            </div>
            <div class="hplan-body mb-40">
              <p>Para você que está começando sua guilda</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-3 space-y-2 text-left">
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Edite as cores do seu painel</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> 3 Sub-usuários</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Armazém ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Distribuições ilimitadas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Cronograma de Eventos</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sem limite de membros</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/free" class="btn">Teste gratuitamente</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Completo</span>
              <h3>R$60 <small>/ mês</small></h3>
            </div>
            <div class="hplan-body mb-40">
              <p>Para ajudar sua guilda a crescer ainda mais</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-3 space-y-2 text-left">
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Edite as cores do seu painel</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sub-usuários ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Armazém ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Distribuições ilimitadas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sorteios ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Leilões ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Cronograma de Eventos</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sistema DKP</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sem limite de membros</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/full" class="btn">Teste gratuitamente</a>
              <small class="d-block mt-2 font-weight-bold">O primeiro mês é por nossa conta!</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Plans',
  props: [

  ]
}
</script>

<style scoped>

.single-hplan {
  background: #191919;
  border-bottom: 3px solid #191919;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}
.single-hplan:hover {
  border-bottom: 3px solid #b622b5;
  background-image: linear-gradient(143deg, #b622b5 -24%, rgb(25, 25, 25) 20%);
}

</style>