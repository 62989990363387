<template>
  <section class="faq-area gray-bg pt-20 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title text-center mb-75">
            <h2>Perguntas frequentes</h2>
            <p>Tem dúvidas sobre nós ainda? Temos as respostas para suas perguntas</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <img src="/assets/img/faq.png" class="img-fluid" alt="">
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <question
                  :hasOpen="true"
                  title="Os valores não estão altos de mais?"
                  text="Temos certeza que nosso painel vale o que é cobrado. E talvez você tenha essas dúvidas por não ter usado nosso painel ainda, fique tranquilo... tudo que fazemos é para garantir que você seja feliz enquanto usa nosso painel."
              />
            </div>
            <div class="col-md-12">
              <question
                  title="O que preciso fazer para começar a usar o painel?"
                  text="Clique no botão na seção acima, preencha suas informações e fique tranquilo! Você tem 1 mês grátis para se adaptar e ver que não pode viver sem nós!"
              />
            </div>
            <div class="col-md-12">
              <question
                  title="Por que nos escolher?"
                  text="Atualmente temos muito mais a oferecer do que nossos concorrentes. Garantimos que você não vai se arrepender de ter nos escolhido."
              />
            </div>
            <div class="col-md-12">
              <question
                  title="E se eu não gostar?"
                  text="Não acreditamos que isso vá acontecer, porém, você pode solicitar reembolso em até 7 dias após a confirmação do pagamento da sua mensalidade!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Question from '@/components/faq/question'

export default {
  name: 'Faq',
  components: {
    Question
  },
  props: [

  ]
}
</script>

<style scoped>



</style>