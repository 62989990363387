<template>
  <section class="unlimited-features pt-115 pb-90">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title text-center mb-75">
            <h2>Recursos ilimitados</h2>
            <p>Acha que temos pouco a oferecer? Veja abaixo mais coisas que você terá conosco!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/confiavel.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Segurança e confiança</h5>
              <p>Garantimos que, estando conosco, você terá a plataforma mais segura para fazer suas vendas.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/atualizar.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Atualizações</h5>
              <p>Estaremos sempre realizando melhorias e adicionando novas ferramentas para que você sempre tenha tudo do melhor.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/certificado-ssl.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Certificado SSL Gratuito</h5>
              <p>Ganhe confiança extra com um certificado digital em sua loja. Se optar por usar nosso domínio, nós o forneceremos gratuitamente.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/forma-de-pagamento.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Leilões e Sorteios</h5>
              <p>Todos os sorteios e leilões são feitos de forma automática e ao vivo, para você não se preocupar.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/servicos-de-suporte.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Suporte 24/7</h5>
              <p>Problemas ou dúvidas com seu painel? Sem problemas, nossa equipe esta sempre pronta para te atender.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-ufeatures mb-55">
            <div class="ufeatures-icon">
              <img src="/assets/img/icon/configurar.png" alt="icon" width="70">
            </div>
            <div class="ufeatures-content fix">
              <h5>Fácil de usar</h5>
              <p>Em pouquissimos minutos você já consegue deixar seu painel funcionando e pronta para gerenciar.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UnlimitedFeatures',
  props: [

  ]
}
</script>

<style scoped>

.single-ufeatures {
  background: #191919;
  border-radius: 5px;
  padding: 37px 28px 28px 36px;
  transition-timing-function: ease-in-out;
  border-bottom: 3px solid #191919;
  transition-duration: .2s;
}
.single-ufeatures:hover {
  border-bottom: 3px solid #b622b5;
  background-image: linear-gradient(143deg, #b622b5 -24%, rgb(25, 25, 25) 20%);
}

</style>