<template>
  <section class="slider-area fix slider-bg" data-background="/assets/img/slider/slider_bg.png">
    <div class="container align-items-center justify-content-center align-content-center">
      <div class="row align-items-center justify-content-center align-content-center">
        <div class="col-lg-5 pt-0">
          <div class="slider-content">
            <h2 class="mb-0">HCStore</h2>
            <h3 class="mb-0">A melhor solução para gerenciamento virtual!</h3>
            <p>Tenha o controle de todas as suas atividades com muita facilidade no painel. Monitore tudo analisando intervalos de tempo mensais ou diários.</p>
          </div>
        </div>
        <div class="col-lg-7 pt-0 text-center">
          <div class="slider-content">
            <h2>Escolha seu jogo</h2>
            <div class="row align-items-center justify-content-center align-content-center">
              <div class="col-md-6 mb-4">
                <div class="typeShop" @click="redirect(0)">
                  <img src="https://ik.imagekit.io/hubcodes/comum/c11eb63b30d21cd5f4ee85793778b6487de9452c_Dj22pKUPL.png?updatedAt=1733056496292" width="100" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="typeShop" @click="redirect(1)">
                  <img src="https://ik.imagekit.io/hubcodes/comum/pngimg.com%20-%20minecraft_PNG81_d4e5s1WEA.png?updatedAt=1733057061621" width="300" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="typeShop" @click="redirect(2)">
                  <img src="https://ik.imagekit.io/hubcodes/comum/Tex_MainMenu_Logo_nrqvFSmfg.png?updatedAt=1733056620534" width="300" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'SelectGame',
  data () {
    return {

    }
  },
  methods: {
    redirect: function (type) {
      if (type == 0) {
        window.location = 'https://hcrp.store/fivem'
      } else if (type == 1) {
        window.location = 'https://hccraft.store/minecraft'
      } else if (type == 2) {
        window.location = 'https://hcgm.pro/throne-and-liberty'
      }

      this.$typeGame = type
    }
  }
}
</script>

<style scoped>

.slider-area {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.slider-area .container {
  min-height: 100vh;
  max-height: 100vh;
}

.slider-area.fix.slider-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(109deg, rgb(66, 6, 76) 17%, rgb(32, 32, 32) 46%);
}

.slider-content {
  padding-top: 0 !important;
}

.typeShop {
  background: #191919;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}
.typeShop:hover {
  background-image: linear-gradient(143deg, #b622b5 -24%, #191919 20%);
  border-bottom: 3px solid #b622b5;
}

@media (max-height: 768px) {
  .slider-area {
    overflow-y: scroll;
  }
}

</style>