var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-bg pt-70"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-between"},[_vm._m(0),_c('div',{staticClass:"col-lg-2 col-sm-6"},[_c('div',{staticClass:"footer-widget mb-30"},[_vm._m(1),_c('div',{staticClass:"fw-link"},[_c('ul',[(this.$typeGame == 0)?_c('li',[_c('a',{attrs:{"href":"#show-room"}},[_vm._v("Conheça nosso produto")])]):_vm._e(),(this.$typeGame == 0)?_c('li',[_c('a',{attrs:{"href":"#templates"}},[_vm._v("Demonstrações")])]):_vm._e(),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])]),_vm._m(6)])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"footer-widget mb-30"},[_c('div',{staticClass:"logo mb-20"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/assets/img/logo/logo.png","alt":"Logo","width":"100"}})])]),_c('div',{staticClass:"footer-text"},[_c('p',[_vm._v("Cansado de sites que dificultam o seu trabalho e atrapalham sua evolução?? Estamos aqui para resolver isso, venda ou gerencie tudo que desejar em seu painel enquanto garantimos sua segurança e felicidade.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-title"},[_c('h4',[_vm._v("Acesso Rápido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#search-domain"}},[_vm._v("Escolha seu dominio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#plans"}},[_vm._v("Planos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#unlimited-features"}},[_vm._v("Recursos ilimitados")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#faq"}},[_vm._v("F.A.Q")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"footer-widget mb-30"},[_c('div',{staticClass:"fw-title"},[_c('h4',[_vm._v("Siga-nos nas Redes Sociais")])]),_c('div',{staticClass:"footer-social"},[_c('a',{attrs:{"href":"https://wa.me/551736009911","target":"_blank"}},[_c('i',{staticClass:"fab fa-whatsapp"})]),_c('a',{attrs:{"href":"https://discord.gg/vHaB4Y2SZA","target":"_blank"}},[_c('i',{staticClass:"fab fa-discord"})]),_c('a',{attrs:{"href":"https://www.youtube.com/@hubcodes_","target":"_blank"}},[_c('i',{staticClass:"fab fa-youtube"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright-area pt-20 pb-20 mt-40"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row border-top"},[_c('div',{staticClass:"col-md-12 text-center pt-40"},[_c('div',{staticClass:"copyright-text"},[_c('p',[_vm._v("© Copyrights 2023 HubCode's Todos os direitos reservados.")])])])])])])
}]

export { render, staticRenderFns }